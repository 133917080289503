import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { getImage } from "gatsby-plugin-image";
import FullWidthImage from "../components/FullWidthImage";
import About2 from "../components/About2";
import FullWidthImage3 from "../components/FullWidthImage3";
import HeaderBanner1 from "../components/HeaderBanner1";
import DotMatrix from "../components/DotMatrix";
import Content21 from "../components/Content21";

// eslint-disable-next-line
export const AboutPageTemplate = ({ title, image, description }) => {
  const heroImage = getImage(image) || image;

  return (
    <>
      <FullWidthImage img={heroImage} title={title} />
    </>
  );
};

AboutPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  discription: PropTypes.string,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout className="font-sans">
      <AboutPageTemplate
        image={frontmatter.image}
        title={post.frontmatter.title}
        description={frontmatter.description}
      />
      <HeaderBanner1 />
      <section className="mx-auto my-16">
        <div className="w-9/12 mx-auto">
          <div className=" mx-auto tile pl-10 shadow-3xl bg-gray bg-opacity-40 rounded-lg p-4">
            <span className="relative text-2xl md:text-4xl">
              Bristol Place Corporation has been providing...
            </span>
          </div>
          <h3 className="text-xl mx-auto my-10 pb-8">
            Bristol Place Corporation has been providing waivered case
            management services since 2007. We began providing contracted CADI
            Waiver case management with Hennepin County and have since added BI,
            DD and non-waivered Rule 185 case management services. Our
            concentration is working with persons diagnosed with mental
            illnesses but we provide services to anyone who qualifies for the
            above listed waivers. With our extensive community involvement and
            coordination, we are able to be successful and understand the
            barriers that may get in the way of individuals reaching their full
            potential. We focus on a person’s strengths, find resources and
            supports, assess on going needs and functioning, as well as looking
            at a person’s physical and mental health conditions. A person’s
            safety is important to us as we continually evaluate their
            vulnerability and risks in the community. Their treatment plan is
            developed by them with our help. This planning can also include
            family members, significant others and providers that are identified
            as being important in their recovery process.
          </h3>
        </div>
        <div className="py-8 bg-bristol-blue-2 text-white">
          <div className="w-9/12 mx-auto mb-8">
            <h2 className="text-4xl mb-4 text-center mx-auto">Our Staff</h2>
            <h3 className="text-xl">
              Our staff are skilled in the process of identifying and assessing
              a wide range of your needs and they are knowledgeable about local
              community resources and how to use those resources for your
              benefit. They have a bachelor's degree in one of the behavioral
              sciences or related fields including, but not limited to, social
              work, psychology, or nursing from an accredited college or
              university and have at least 1 year experience working with
              individuals living with a disability. Our staff are kind, empathic
              and fun to work with. We are proud of the diverse eclectic team we
              have and are confident you will enjoy having them a part of your
              team.
            </h3>
          </div>
        </div>
        <div className="pt-4">
          <DotMatrix />
        </div>
        <div className="w-9/12 mx-auto mb-12 lg:mb-0">
          <Content21 />
        </div>
        {/* <div className='pt-8 pb-4 -mt-24'>
          <DotMatrix />
        </div> */}
        <div className="w-9/12 mx-auto">
          <About2 />
        </div>
      </section>
      <HeaderBanner1 />
      <FullWidthImage3 />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        description
      }
    }
  }
`;
