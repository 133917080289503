import * as React from "react";

const Content21 = () => {
  return (
    <>
      <div className="md:grid grid-cols-12 grid-rows-6 mx-auto">
      {/* <div className="z-10 row-start-1 lg:mt-4 lg:-ml-2 row-end-5 col-start-1 col-end-6 h-full md:w-[115%]"> */}
          <img className="z-10 row-start-1 lg:mt-4 lg:-ml-2 row-end-5 col-start-1 col-end-6 rounded-t-lg h-[460px] lg:rounded shadow-3xl" src={"/img/2-new.jpg"} alt="" />
        {/* </div> */}
        <div className="flex flex-row md:flex-row row-start-1 row-end-7 col-start-3 col-end-13 self-center p-8 rounded-b-lg lg:rounded shadow-3xl">
          <div className="hidden md:block md:basis-5/12"></div>
          <div className="md:basis-7/12">
            <h1 className="text-2xl font-bold mt-0 pb-4">Bristol Place Corporation <br />is about integrity...</h1>
            <p className="text-base lg:text-xl mb-2 !leading-6"> At Bristol Place Corporation, we take our responsibilities
              seriously and work hard to support the people we serve. But we
              also believe you can do great work while having a great time. Our
              company culture is a reflection of that commitment to working hard  
              in an inclusive, supportive atmosphere. By celebrating our
              diversity and acknowledging our individual contributions, we’ve
              created a welcoming environment where everyone can feel safe to be
              their authentic self. We are advocates not just for the individuals we serve,
              but for each other as well, championing our successes and
              recognizing our achievements. Our team has become a family and we
              prioritize respect and compassion in everything we do.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};



export default Content21;
