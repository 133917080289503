import React from "react";
import { FaCrosshairs, FaEye, FaHandHoldingHeart } from "react-icons/fa";

export default function About2() {
  return (
    <div className='px-8 bg-bristol-blue-1 shadow-3xl rounded bg-opacity-50 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl'>
      <div className='flex flex-col-reverse md:items-stretch items-center justify-center'>
        <div className='flex flex-col items-center justify-center'>
          <div className='flex flex-col items-center'>
            <div
              className='w-20 h-20 bg-white shadow rounded-full flex items-center justify-center'
              role='img'
              aria-label='crosshairs'
            >
              <FaCrosshairs className='fill-icon-blue h-8 text-6xl' />
            </div>
            <h3 className='text-xl mt-4'>MISSION STATEMENT</h3>
            <p className='text-xl leading-6 mt-6 text-center w-full'>
              Our mission is to provide quality case management services by
              focusing on your skills, strengths and abilities. We also take
              into consideration your natural supports, routines and rituals,
              hopes, dreams and aspirations and what you like to do for fun! We
              are committed to being accessible, responsive and person centered
              which allows individuals to choose their own care givers, their
              supports, as well as their housing and employment opportunities or
              preferences. It is our commitment to make sure individuals are
              given every opportunity to live their lives to the fullest with
              the necessary supports in place to ensure their health and safety
              needs are being met.
            </p>
          </div>
          <div className='mt-7 flex flex-col items-center'>
            <div
              className='w-20 h-20 bg-white shadow rounded-full flex items-center justify-center'
              role='img'
              aria-label='phone'
            >
              <FaEye className='fill-icon-blue h-8 text-6xl' />
            </div>
            <h3 className='text-xl mt-4'>VISION</h3>
            <p className='text-xl leading-6 mt-6 text-center w-full'>
              Our vision is to empower and support individuals in their journey
              to achieve their goals and live their lives to the fullest
              potential. Each person has their own vision on how to reach that
              outcome and we are here to encourage and support them to realize
              their goals are attainable.
            </p>
          </div>
          <div className='mt-7 flex flex-col items-center'>
            <div
              className='w-20 h-20 bg-white shadow rounded-full flex items-center justify-center'
              role='img'
              aria-label='ideas'
            >
              <FaHandHoldingHeart className='fill-icon-blue h-8 text-6xl' />
            </div>
            <h3 className='text-xl mt-4'>VALUES</h3>
            <p className='text-xl leading-6 mt-6 text-center w-full'>
              We value each individual for who they are and meet them where they
              are at in their recovery process. We embrace diversity and work
              hard to learn and grow from new experiences, beliefs and ideas. We
              are committed to providing a safe, non-judgmental environment
              where people feel comfortable, supported and a part of their
              progression. We believe there is an opportunity for everyone to
              achieve their greatest potential when there is help and support
              from others within their communities.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
